import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './features/app/App.tsx';
import './index.css';
import { configuratorStore } from './store';
import { Provider } from 'react-redux';

const startConfiguratorButton = document.getElementById(
  'start-configurator'
)! as HTMLButtonElement;

const isUpsell = startConfiguratorButton.dataset.isUpsell === 'true';

const initialPreview = document.getElementById('initial-preview')!;
const rootElement = document.getElementById('customer-ui-root')!;

const render = () => {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <Provider store={configuratorStore}>
        <App />
      </Provider>
    </React.StrictMode>
  );
};

const customizeListener = () => {
  initialPreview.remove();
  globalThis.scrollTo({ top: 0, behavior: 'instant' });
  document.dispatchEvent(new CustomEvent('showReactApp'));
};

if (rootElement.className.includes('render-immediately')) {
  render();
  setTimeout(() => {
    document.dispatchEvent(new CustomEvent('showReactApp'));
  }, 1000);
} else {
  if (isUpsell) {
    startConfiguratorButton.textContent =
      'This product is not available for individual purchase';
  }

  document.addEventListener(
    'loaded',
    ({ detail }: CustomEventInit) => {
      if (detail) {
        const params = new URLSearchParams(window.location.search);
        const cartItemId = params.get('cart_item_id');
        const legacyProductCode = params.get('legacy_product_code');
        if (cartItemId || legacyProductCode) {
          customizeListener();
        } else if (isUpsell) {
          return; // Do nothing
        } else {
          startConfiguratorButton.addEventListener('click', customizeListener);
          startConfiguratorButton.textContent = 'Start Customizing';
          startConfiguratorButton.disabled = false;
        }
      } else {
        startConfiguratorButton.textContent = 'Something went wrong';
      }
    },
    { once: true }
  );
  render();
  startConfiguratorButton.disabled = true;
}
